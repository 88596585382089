import { Navigate } from "react-router-dom"
import Pages from "./Pages"

const GuestRoutes = ({ isAuth, permissions }) => [
  isAuth
    ? {
        path: "forbidden",
        element: <Pages.Forbidden />,
        children: [],
      }
    : {
        path: "signin",
        index: true,
        element: <Pages.Signin />,
      },
  {
    path: "*",
    element: (
      <Navigate to={isAuth && permissions.length === 1 ? "/forbidden" : "/signin"} replace />
    ),
  },
]

export default GuestRoutes
